import React from 'react';
import _ from "lodash";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {Field, Form, withFormik} from "formik";
import { Link } from "react-router-dom";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ButtonIcon from "../../components/styled/ButtonIcon";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import Assignment from "@material-ui/icons/Assignment";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import Save from "@material-ui/icons/Save";
import Cancel from "@material-ui/icons/Cancel";
import EventIcon from '@material-ui/icons/Event';

import InfoInstance from "./components/InfoInstance";
import IndentityIntance from "./components/IndentityIntance";
import ContactIntance from "./components/ContactIntance";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ImageUploader from "react-images-upload";
import { InputLabel } from '@material-ui/core';
import Datetime from "react-datetime";
import SelectTo from 'react-select';


const EditSubscriptionForm = (props) => {

    const { 
      errors,
      touched,
      setFieldValue,
      submitForm,
      cities,
      instanceType,
      values,
      updateAppToken,
      archivedAllappTokens,
      partners,
      classes,
      paginationState } = props;
    const { t } = useTranslation("instances");
    return (
      <Form>
        <GridContainer>
          <GridItem xs={6}>
            <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardHeader>
                    <CardIcon color="info">
                      <Apps />
                    </CardIcon>
                    <h4>{t("instances.new.dataInfo")}</h4>
                  </CardHeader>
                  <CardBody>
                    <InfoInstance
                      updateAppToken={updateAppToken}
                      values={values}
                      errors={errors}
                      touched={touched}
                      cities={cities}
                      setFieldValue={setFieldValue}
                      editOpportunity={true}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardHeader>
                    <CardIcon color="info">
                      <Apps />
                    </CardIcon>
                    <h4>{t("instances.specialPermissions.title")}</h4>
                  </CardHeader>
                  <CardBody>
                    <List>
                      <ListItem>
                        <ListItemText
                          primary={t(
                            "instances.specialPermissions.archivedTokens"
                          )}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="archivar"
                            onClick={archivedAllappTokens}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>

          <GridItem xs={6}>
            <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardHeader>
                    <CardIcon color="info">
                      <Assignment />
                    </CardIcon>
                    <h4>{t("instances.new.idetityInfo")}</h4>
                  </CardHeader>
                  <CardBody>
                    <IndentityIntance errors={errors} touched={touched} />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12}>
                <Card>
                  <CardHeader>
                    <CardIcon color="info">
                      <AssignmentInd />
                    </CardIcon>
                    <h4>{t("instances.new.contactInfo")}</h4>
                  </CardHeader>
                  <CardBody>
                    <ContactIntance errors={errors} touched={touched} />
                  </CardBody>
                </Card>
              </GridItem>

              <GridItem xs={12}>
                <Card>
                  <CardHeader>
                    <CardIcon color="info">
                      <EventIcon />
                    </CardIcon>
                    <h4>{t("instances.forms.fields.updateDate.label")}</h4>
                  </CardHeader>
                  <CardBody>
                    <GridItem xs={12} style={styles.marginBottom}>
                      <InputLabel>
                        {t("instances.forms.fields.partners.label")}
                      </InputLabel>
                      <Field
                        name="franchise"
                        render={({ field }) => {
                          return (
                            <SelectTo
                              value={partners.find(partner => partner._id === field.value)}
                              onChange={(selectedOption) =>
                                setFieldValue("franchise", selectedOption._id)
                              }
                              options={partners}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option}
                            />
                          );
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <InputLabel>
                        {t("instances.forms.fields.updateDate.placeholder")}
                      </InputLabel>
                      <Field
                        name="updateInstance"
                        render={({ field }) => {
                          return (
                            <Datetime
                              name={"updateInstance"}
                              timeFormat={false}
                              defaultValue={field.value}
                              onChange={(e) => {
                                if (e === "") {
                                  setFieldValue("updateInstance", e);
                                } else {
                                  setFieldValue("updateInstance", e._d);
                                }
                              }}
                            />
                          );
                        }}
                      />
                    </GridItem>
                  </CardBody>
                </Card>
              </GridItem>

              <GridItem xs={12}>
                <Field
                  name={"icons"}
                  render={({ field }) => {
                    return (
                      <ImageUploader
                        defaultImages={field.value ? [field.value[480]] : []}
                        withIcon={true}
                        singleImage={true}
                        withLabel={false}
                        withPreview={true}
                        buttonText={t("instances.forms.fields.image.label")}
                        onChange={(image) => {
                          setFieldValue("icon", image);
                        }}
                        imgExtension={[".jpg", ".png"]}
                        maxFileSize={5242880}
                      />
                    );
                  }}
                />
              </GridItem>

              <GridContainer justify="center">
                
                <GridItem xs={3}>
                  <Link to={{pathname: `/instances/subscriptions`, state: {paginationState: paginationState} }}>
                    <ButtonIcon icon={Cancel} label={t("instances.cancel")} />
                  </Link>
                </GridItem>
                <GridItem xs={3}>
                  <ButtonIcon
                    icon={Save}
                    label={t("instances.save")}
                    color={"info"}
                    onClick={() => {
                      submitForm();
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Form>
    );
}

const styles ={
  marginBottom: {
    marginBottom: '4%'
  }
}

const EditSubscriptionFormWK = withFormik({
    mapPropsToValues: ({ instance }) => {
        const {
          name,
          address,
          category,
          phone,
          description,
          webPage,
          requirementDescription,
          identification,
          contact,
          timezone,
          city,
          location,
          icon,
          icons,
          token,
          firebaseProject,
          updateInstance,
          maxAppUsers,
          franchise,
          canAccessNewApp
        } = instance;

        let d = new Date();
        let year = d.getFullYear();
        let month = d.getMonth();
        let day = d.getDate();
        return {
          updateInstance: updateInstance
            ? new Date(updateInstance)
            : new Date(year + 1, month, day),
          name: name,
          appType: 1,
          address: address,
          category: category,
          phone: phone,
          description: description,
          webPage: webPage,
          maxAppUsers: maxAppUsers,
          instanceStatus: "Active",
          instanceType: "Subscription",
          requirementDescription: requirementDescription,
          identification: {
            type: identification.type,
            value: identification.value,
          },
          contact: {
            name: contact.name,
            phone: contact.phone,
            email: contact.email,
          },
          timezone: {
            zone: timezone.zone,
            utc: timezone.utc,
          },
          city: {
            id: city.id,
            timezone: city.timezone,
            continentName: city.continentName,
            geonameId: city.geonameId,
            continentCode: city.continentCode,
            countryIsoCode: city.countryIsoCode,
            subdivisionName: city.subdivisionName,
            cityName: city.cityName,
            countryName: city.countryName,
          },
          location: {
            coordinates: [location.coordinates[0], location.coordinates[1]],
          },
          icon: icon,
          icons: icons,
          token: token,
          firebaseProject: firebaseProject,
          franchise: franchise,
          canAccessNewApp: canAccessNewApp
        };
      },
      handleSubmit: (values, { props }) => {
        props.submitSuccess(values);
      }
})(withStyles(regularFormsStyle)(EditSubscriptionForm));

export default EditSubscriptionFormWK;
