import React from "react";
import { Form, withFormik, Field } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import Assignment from "@material-ui/icons/Assignment";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import ButtonIcon from "../../components/styled/ButtonIcon";

import Save from "@material-ui/icons/Save";
import Cancel from "@material-ui/icons/Cancel";

import InfoInstance from "./components/InfoInstance";
import IndentityIntance from "./components/IndentityIntance";
import ContactIntance from "./components/ContactIntance";

import ImageUploader from "react-images-upload";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { hasError } from '../../utils/forms/inputs';


const EditDemoFormSchema = yup.object().shape({
  name: yup.string().required(),
  appType: yup.number(),
  address: yup.string(),
  category: yup.number(),
  phone: yup.string(),
  description: yup.string(),
  webPage: yup.string(),
  maxAppUsers: yup.number(),
  instanceStatus: yup.string(),
  requirementDescription: yup.string(),
  identification: yup.object().shape({
    type: yup.number(),
    value: yup.string()
  }),
  contact: yup.object().shape({
    name: yup.string(),
    phone: yup.string(),
    email: yup.string()
  }),
  timezone: yup.object().shape({
    zone: yup.string(),
    utc: yup.string()
  }),
  token: yup.string().required(),
  firebaseProject: yup.number()
});

const EditDemoForm = props => {
  // Formik bag
  const {
    errors,
    touched,
    setFieldValue,
    submitForm,
    cities,
    instanceType,
    values,
    updateAppToken,
    archivedAllappTokens,
    paginationState
  } = props;
  const { t } = useTranslation("instances");

  return (
    <Form>
      <GridContainer>
        <GridItem xs={6}>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader>
                  <CardIcon color="info">
                    <Apps />
                  </CardIcon>
                  <h4>{t("instances.new.dataInfo")}</h4>
                </CardHeader>
                <CardBody>
                  <InfoInstance
                    updateAppToken={updateAppToken}
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    cities={cities}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader>
                  <CardIcon color="info">
                    <Apps />
                  </CardIcon>
                  <h4>{t("instances.specialPermissions.title")}</h4>
                </CardHeader>
                <CardBody>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary={t(
                          "instances.specialPermissions.archivedTokens"
                        )}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="archivar"
                          onClick={archivedAllappTokens}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={6}>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader>
                  <CardIcon color="info">
                    <Assignment />
                  </CardIcon>
                  <h4>{t("instances.new.idetityInfo")}</h4>
                </CardHeader>
                <CardBody>
                  <IndentityIntance errors={errors} touched={touched} />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12}>
              <Card>
                <CardHeader>
                  <CardIcon color="info">
                    <AssignmentInd />
                  </CardIcon>
                  <h4>{t("instances.new.contactInfo")}</h4>
                </CardHeader>
                <CardBody>
                  <ContactIntance errors={errors} touched={touched} />
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12}>
              <Field
                name={"icons"}
                render={({ field }) => {
                  return (
                    <ImageUploader
                      defaultImages={field.value ? [field.value[480]] : []}
                      withIcon={true}
                      singleImage={true}
                      withLabel={false}
                      withPreview={true}
                      buttonText={t("instances.forms.fields.image.label")}
                      onChange={(image) => {
                        setFieldValue("icon", image);
                      }}
                      imgExtension={[".jpg", ".png"]}
                      maxFileSize={5242880}
                      buttonStyles={
                        hasError(errors, "icon") ? styles.error : {}
                      }
                    />
                  );
                }}
              />
            </GridItem>

            <GridContainer justify="center">
              <GridItem xs={3}>
                <Link to={{pathname: `/instances/${instanceType}`, state: {paginationState: paginationState} }}>
                  <ButtonIcon icon={Cancel} label={t("instances.cancel")} />
                </Link>
              </GridItem>
              <GridItem xs={3}>
                <ButtonIcon
                  icon={Save}
                  label={t("instances.save")}
                  color={"info"}
                  onClick={() => {
                    submitForm();
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </Form>
  );
};

const styles ={
  marginTop: {
      marginTop: '4%'
  },
  error: {
      backgroundColor: "red"
  }
}

const EditDemoFormWF = withFormik({
  validationSchema: EditDemoFormSchema,
  mapPropsToValues: ({instance}) => {
    const {
      name,
      address,
      category,
      phone,
      token,
      description,
      webPage,
      requirementDescription,
      identification,
      contact,
      timezone,
      city,
      location,
      icon,
      icons,
      firebaseProject,
      maxAppUsers,
      canAccessNewApp
    } = instance;

    return {
      name: name,
      appType: 1,
      address: address,
      category: category,
      phone: phone,
      token: token,
      description: description,
      webPage: webPage,
      maxAppUsers: maxAppUsers,
      instanceStatus: "Demo",
      instanceType: "Demo",
      requirementDescription: requirementDescription,
      identification: {
        type: identification.type ? identification.type : 0,
        value: identification.value ? identification.value : ''
      },
      contact: {
        name: contact.name,
        phone: contact.phone,
        email: contact.email
      },
      timezone: {
        zone: timezone.zone,
        utc: timezone.utc
      },
      city: {
        id : city.id,
        timezone : city.timezone,
        continentName : city.continentName,
        geonameId : city.geonameId,
        continentCode : city.continentCode,
        countryIsoCode : city.countryIsoCode,
        subdivisionName : city.subdivisionName,
        cityName : city.cityName,
        countryName : city.countryName
      },
      location: {
        coordinates: [location.coordinates[0],location.coordinates[1]]
      },
      icon: icon,
      icons: icons,
      firebaseProject: firebaseProject ? firebaseProject : 0,
      canAccessNewApp: canAccessNewApp
    };
  },
  handleSubmit: (values, { props }) => {
    props.submitSuccess(values);
  }
})(EditDemoForm);

export default EditDemoFormWF;
